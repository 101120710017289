import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Image, Section, Icon, Span, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdAssignmentTurnedIn } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Welcome to Avis Rent a Car
			</title>
			<meta name={"description"} content={"Welcome to Avis Rent a Car, where your journey begins with a touch of simplicity and convenience. "} />
			<meta property={"og:title"} content={"Home | Welcome to Avis Rent a Car"} />
			<meta property={"og:description"} content={"Welcome to Avis Rent a Car, where your journey begins with a touch of simplicity and convenience. "} />
			<meta property={"og:image"} content={"https://fun.winterstarol.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fun.winterstarol.com/img/car-rental-icon-png.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fun.winterstarol.com/img/car-rental-icon-png.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fun.winterstarol.com/img/car-rental-icon-png.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fun.winterstarol.com/img/car-rental-icon-png.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fun.winterstarol.com/img/car-rental-icon-png.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fun.winterstarol.com/img/car-rental-icon-png.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Hero-12">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="center"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="80px 50px 80px 50px"
					lg-width="100%"
					sm-padding="80px 25px 80px 25px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					md-padding="80px 25px 80px 25px"
				>
					<Box min-width="100px" min-height="100px" display="flex" flex-direction="column">
						<Text color="--primary" margin="0px 0px 25px 0px" font="normal 400 16px/1.5 --fontFamily-sans" letter-spacing="1px">
						Book your next rental with us and experience the difference
						</Text>
						<Text
							margin="0px 0px 20px 0px"
							font="normal 400 56px/1.2 --fontFamily-serifGaramond"
							sm-font="normal 700 42px/1.2 --fontFamily-sans"
							color="--dark"
							lg-margin="0px 0px 35px 0px"
						>
							Avis Rent a Car
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" display="flex" sm-flex-direction="column">
						<Box
							min-width="100px"
							min-height="100px"
							display="flex"
							flex-direction="column"
							width="70%"
							lg-width="60%"
							sm-width="100%"
							sm-margin="0px 0px 25px 0px"
							justify-content="flex-end"
						>
							<Text margin="0px 0px 25px 0px" font="--lead" color="--primary" padding="0px 35px 0px 0px">
							Avis Rent a Car, where your journey begins with a touch of simplicity and convenience. Whether you're exploring new destinations or traveling for business, we're here to make your car rental experience seamless and enjoyable. Our friendly team is dedicated to ensuring you drive away satisfied, knowing you've made the perfect choice for your travel needs.
							</Text>
							<Link href="/contact" color="--primary">
								Contact Us
							</Link>
						</Box>
					</Box>
				</Box>
				<Image
					src="https://fun.winterstarol.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
				/>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			display="flex"
			quarkly-title="Advantages/Features-28"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 16px 0px 0px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image src="https://fun.winterstarol.com/img/2.jpg" display="block" border-radius="32px" />
			</Box>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text as="p" font="--base" color="--primary" margin="0px 0px 8px 0px">
					About Us
				</Text>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Why Choose Avis?
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--darkL2"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
				>
					At Avis Rent a Car, we focus on providing top-quality vehicles at competitive prices. Our fleet is modern, well-maintained, and perfect for any trip – from compact cars for city driving to spacious SUVs for family adventures. We pride ourselves on our transparent pricing and straightforward rental process, ensuring you can focus on your journey without any hassles.
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="md"
							icon={MdAssignmentTurnedIn}
							size="20px"
							color="--primary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--greyD2" font="18px/27px --fontFamily-sans">
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Competitive Rates: 
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								We offer the best prices in the market, ensuring you get great value every time you rent with us.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="md"
							icon={MdAssignmentTurnedIn}
							size="20px"
							color="--primary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--darkL2" font="18px/27px --fontFamily-sans">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Quality Vehicles: {" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Each car in our fleet is meticulously maintained and undergoes rigorous checks to ensure your safety and comfort.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="md"
							icon={MdAssignmentTurnedIn}
							size="20px"
							color="--primary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" color="--darkL2">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Exceptional Service: {" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--greyD2"
							>
								Our team is passionate about helping you find the right car to match your needs, with the warmth and friendliness you deserve.
							</Span>
						</Text>
					</Box>
				</List>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});